import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'


/**
 * Base
 */
// Debug
const gui = new GUI()
gui.hide();
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// World
// const hdrTextureURL = new URL('img/symmetrical_garden_02_2k.hdr', import.meta.url)
// const hdrTextureURL = new URL('img/brown_photostudio_02_2k.hdr', import.meta.url)
// const hdrTextureURL = new URL('img/meadow_2k.hdr', import.meta.url)
// const hdrTextureURL = new URL('img/photo_studio_01_2k.hdr', import.meta.url)
// const hdrTextureURL = new URL('img/studio_small_08_2k.hdr', import.meta.url)
const hdrTextureURL = new URL('img/PANO_20240821_144212_2.hdr', import.meta.url)




/**
 * Models
 */
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const loadingManager = new THREE.LoadingManager();

const gltfLoader = new GLTFLoader(loadingManager);

const rgbeLoader = new RGBELoader(loadingManager);


loadingManager.onStart = function (url, itemsLoaded, total) {
  console.log('Loading process has started!');
};

loadingManager.onProgress = function (url, itemsLoaded, total) {
  console.log(`PROGREES Started loading: ${url}
number of items loaded: ${itemsLoaded}
total number of items: ${total}
    `);
};

loadingManager.onLoad = function () {
  console.log('Loading process has been completed!');
};
loadingManager.onError = function (url) {
  console.error(`Got a problem loading: ${url}`);
};

gltfLoader.setDRACOLoader(dracoLoader)

// const fbxLoader = new FBXLoader();

// fbxLoader.load('/models/naon.fbx', (object) => {
//   scene.add(object);
// });


let mixer = null

gltfLoader.load(
  // '/models/Fox/glTF/Fox.gltf',
  // '/models/Fox/suganwehgltf.gltf',
  // '/models/FastIteration.glb',
  // '/models/CustomShaders.glb',
  // '/models/SiteplanStarting3.glb',
  // '/models/sitelahan3/modelA.gltf',
  // 'models/SERENO/sereno.gltf',
  // 'models/serenoA/sereno.gltf',
  'models/Serina/sereno.glb',
  // 'models/Serina/sereno.gltf',

  (gltf) => {
    gltf.scene.scale.set(0.25, 0.25, 0.25)

    const model = gltf.scene
    // model.castShadow = true
    // model.receiveShadow = true
    model.traverse(function (child) {
      if (child.isMesh) {

        // if (child.material) {
        //   // child.material = new THREE.MeshStandardMaterial({ color: 'blue' });
        // }
        // child.castShadow = true;
        // child.receiveShadow = true;
        // if (child.material.map) child.material.map.anisotropy = 16



      }
      if (child.isObject3D) {
        // if (child.name === "Rumah" || child.name === "Tree_oak_1_N070221" || child.name === "Car_Rig001") {
        // child.visible = false;
        child.castShadow = true;
        child.receiveShadow = true;
        // child.material = new THREE.MeshStandardMaterial({ color: '#f20000' });
        // console.log('berhasil = ' + child.name);
        // }
      }
    });

    // model.children[0].material = new THREE.MeshStandardMaterial({ color: '#FF0000' });
    // model.children[0].visible = false

    // model.children[0].visible = false
    // model.children[0].castShadow = true
    // model.children[1].castShadow = true
    // model.children[0].receiveShadow = true
    // model.children[1].receiveShadow = true
    console.log(model.children)
    scene.add(model)


    // Animation
    mixer = new THREE.AnimationMixer(model)
    mixer.clipAction(gltf.animations[0]).play();
    // renderer.setAnimationLoop(mixer);

    // const action = mixer.clipAction(gltf.animations)
    // mixer.play()
  }
)




//===


//change material on object
// setMaterialsOnGLTF(object3D) {
//   if (object3D.material) {
//     const newMaterial = new THREE.MeshPhongMaterial({ map: object3D.material.map });
//     object3D.material = newMaterial;
//   }
//   if (!object3D.children) {
//     return;
//   }
//   for (let i = 0; i < object3D.children.length; i++) {
//     Utilities.setMaterialsOnGLTF(object3D.children[i]);
//   }
// }



// new RGBELoader()
// 					.setPath( 'textures/equirectangular/' )
// 					.load( 'royal_esplanade_1k.hdr', function ( texture ) {

// 						texture.mapping = THREE.EquirectangularReflectionMapping;

// 						scene.background = texture;
// 						scene.environment = texture;

// 						render();

// 						// model

// 						const loader = new GLTFLoader().setPath( 'models/gltf/DamagedHelmet/glTF-instancing/' );
// 						loader.load( 'DamagedHelmetGpuInstancing.gltf', function ( gltf ) {

// 							scene.add( gltf.scene );

// 							render();

// 						} );

// 					} );




//const material = new THREE.MeshNormalMaterial()

// const fbxLoader = new FBXLoader()
// fbxLoader.load(
//   '/models/naon.fbx',
//   (object) => {
//     // object.traverse(function (child) {
//     //     if ((child as THREE.Mesh).isMesh) {
//     //         // (child as THREE.Mesh).material = material
//     //         if ((child as THREE.Mesh).material) {
//     //             ((child as THREE.Mesh).material as THREE.MeshBasicMaterial).transparent = false
//     //         }
//     //     }
//     // })
//     // object.scale.set(.01, .01, .01)
//     scene.add(object)
//   },
//   (xhr) => {
//     console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
//   },
//   (error) => {
//     console.log(error)
//   }
// )


//=====================



//====================
/**
 * Floor
 */
// const floor = new THREE.Mesh(
//   new THREE.PlaneGeometry(10, 10),
//   new THREE.MeshStandardMaterial({
//     color: '#444444',
//     metalness: 0,
//     roughness: 0.5
//   })
// )
// floor.receiveShadow = true
// floor.rotation.x = - Math.PI * 0.5
// scene.add(floor)

/**
 * Lights
 */

const ambientLight = new THREE.AmbientLight(0xffffff, 0.4)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 2.8)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 1
directionalLight.shadow.camera.left = -0.08
directionalLight.shadow.camera.top = -0.01
directionalLight.shadow.camera.right = 0
directionalLight.shadow.camera.bottom = -1
directionalLight.position.set(-1, 3, 10)
// - 0.08737283239063422, 0.7367707033419888, 4.330002236027721
directionalLight.position.set(-1, 3, 10)
directionalLight.target.position.set(0, 0, 0)
scene.add(directionalLight)
scene.add(directionalLight.target)

let lightHelper2;
lightHelper2 = new THREE.DirectionalLightHelper(directionalLight);
// scene.add(lightHelper2);

let rollup = gui.addFolder('Directional')
rollup.add(directionalLight, 'visible')
rollup.add(directionalLight.position, 'x').step(0.01)
rollup.add(directionalLight.position, 'y').step(0.01)
rollup.add(directionalLight.position, 'z').step(0.01)
rollup.add(directionalLight, 'intensity').step(0.01)
rollup.add(directionalLight.shadow.camera, 'far').step(0.01)
rollup.add(directionalLight.shadow.camera, 'left').step(0.01)
rollup.add(directionalLight.shadow.camera, 'right').step(0.01)
rollup.add(directionalLight.shadow.camera, 'bottom').step(0.01)
// ===


const light = new THREE.PointLight(0xc9b712, 1.73, 0.38, 0.68);
light.position.set(0, 0.67, 0.76);
light.power = 50
// light.castShadow = true
scene.add(light);
let rollup3 = gui.addFolder('Light')
rollup3.add(light, 'visible')
rollup3.add(light.position, 'x').step(0.01)
rollup3.add(light.position, 'y').step(0.01)
rollup3.add(light.position, 'z').step(0.01)
rollup3.add(light, 'intensity').step(0.01)
rollup3.add(light, 'distance').step(0.01)
rollup3.add(light, 'decay').step(0.01)
const plHelper = new THREE.PointLightHelper(light, 0.1)
// scene.add(light, plHelper)


// ===


// ========================

// const loaders = new THREE.TextureLoader().setPath('textures/');
// const filenames = ['disturb.jpg', 'colors.png', 'uv_grid_opengl.jpg'];

// const textures = { none: null };

// for (let i = 0; i < filenames.length; i++) {

//   const filename = filenames[i];

//   const texture = loaders.load(filename);
//   texture.minFilter = THREE.LinearFilter;
//   texture.magFilter = THREE.LinearFilter;
//   texture.generateMipmaps = false;
//   texture.colorSpace = THREE.SRGBColorSpace;

//   textures[filename] = texture;

// }


// white spotlight shining from the side, modulated by a texture, casting a shadow

const spotLight = new THREE.SpotLight(0xffffff, 500);
// spotLight.position.set(-0.4, 5.82, -0.3);
//model a
// spotLight.position.set(-0.28, 2.16, -0.3);
// spotLight.angle = -1//Math.PI / 10;
// spotLight.penumbra = 0.88;
// spotLight.decay = 2.78;
// spotLight.distance = -2.73 //-1.91;

spotLight.position.set(-2.52, 1.89, 0.05);
spotLight.penumbra = 0.7;
spotLight.decay = 3.41;
spotLight.distance = -2.73 //-1.91;
spotLight.angle = -1//Math.PI / 10;


// spotLight.map = textures['models/Serina/TEXTURES/Poliigon_StoneQuartzite_8060_BaseColor.jpg'];

spotLight.castShadow = true;
spotLight.shadow.mapSize.width = 1024;
spotLight.shadow.mapSize.height = 1024;
spotLight.shadow.focus = 1;
spotLight.shadow.bias = -0.01;
spotLight.shadow.camera.near = 1;
spotLight.shadow.camera.far = 10;
spotLight.shadow.camera.fov = 30;
scene.add(spotLight);

let lightHelper;
lightHelper = new THREE.SpotLightHelper(spotLight);
let rollup2 = gui.addFolder('spotLight')
rollup2.add(spotLight, 'visible')
rollup2.add(spotLight.position, 'x').step(0.01)
rollup2.add(spotLight.position, 'y').step(0.01)
rollup2.add(spotLight.position, 'z').step(0.01)
rollup2.add(spotLight, 'intensity').step(0.1)
rollup2.add(spotLight, 'penumbra').step(0.01)
rollup2.add(spotLight, 'decay').step(0.01)
rollup2.add(spotLight, 'distance').step(0.01)
rollup2.add(spotLight, 'angle').step(0.01)
// scene.add(lightHelper);
// =======================


//===


const sL = new THREE.SpotLight(0xfffff3, 200);

//Model b
sL.position.set(1.78, 2.16, -2);
sL.penumbra = 0.7;
sL.decay = 3.8;
sL.distance = -12.52 //-9.5 //-1.91;
sL.angle = 0.49// -0.63//Math.PI / 10;


// sL.map = textures['models/Serina/TEXTURES/Poliigon_StoneQuartzite_8060_BaseColor.jpg'];

sL.castShadow = true;
sL.shadow.mapSize.width = 1024;
sL.shadow.mapSize.height = 1024;
sL.shadow.focus = 1;
sL.shadow.bias = -0.01;
sL.shadow.camera.near = 1;
sL.shadow.camera.far = 10;
sL.shadow.camera.fov = 30;
scene.add(sL);

let lightHelper3
lightHelper3 = new THREE.SpotLightHelper(sL);
rollup3 = gui.addFolder('spotLight')
rollup3.add(sL, 'visible')
rollup3.add(sL.position, 'x').step(0.01)
rollup3.add(sL.position, 'y').step(0.01)
rollup3.add(sL.position, 'z').step(0.01)
rollup3.add(sL, 'intensity').step(0.1)
rollup3.add(sL, 'penumbra').step(0.01)
rollup3.add(sL, 'decay').step(0.01)
rollup3.add(sL, 'distance').step(0.01)
rollup3.add(sL, 'angle').step(0.01)
// scene.add(lightHelper);
// =======================


/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}

window.addEventListener('resize', () => {
  // Update sizes
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight

  // Update camera
  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  // Update renderer
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(40, sizes.width / sizes.height, 0.1, 100)
// camera.position.set(8.25, 9.4, -9.5); //awal
// camera.position.set(-0.285, 0.90, 4.20); // ke dua
// camera.position.set(-0.0866, 0.840, 4.366); // ke  3
camera.position.set(-0.08737283239063422, 0.7367707033419888, 4.330002236027721); // ke  4
scene.add(camera)


// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0.35, 0)
controls.enableDamping = true
controls.minDistance = 2;
controls.maxDistance = 8;
controls.maxPolarAngle = THREE.MathUtils.degToRad(90);
// controls.target.set(0, 0.5, 0);


// Progress bar
const progressBar = document.getElementById('progress-bar');

loadingManager.onProgress = function (url, loaded, total) {
  progressBar.value = (loaded / total) * 100;
};
const progressBarContainer = document.querySelector('.progress-bar-container');

loadingManager.onLoad = function () {
  progressBarContainer.style.display = 'none';
};

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
  antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);

/**
 * World - HDRI Texture
*/
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = .5;
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.shadowMap.enabled = true
renderer.shadowMap.type = true;
// container.appendChild(renderer.domElement);

renderer.outputColorSpace = THREE.SRGBColorSpace;

const loader = new RGBELoader()
loader.load(hdrTextureURL, function (texture) {
  texture.mapping = THREE.EquirectangularReflectionMapping
  // scene.background = texture
  scene.environment = texture
});

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () => {
  const elapsedTime = clock.getElapsedTime()
  const deltaTime = elapsedTime - previousTime
  previousTime = elapsedTime
  // Model animation
  if (mixer) {
    mixer.update(deltaTime)
  }

  // Update controls
  controls.update()

  // Render
  renderer.render(scene, camera)

  //Light
  // spotLight.position.set(
  //   camera.position.x + 10,
  //   camera.position.x + 10,
  //   camera.position.x + 10,
  // )

  // Call tick again on the next frame
  window.requestAnimationFrame(tick)

  // =================
  // console.log(camera.position)
}
tick()
